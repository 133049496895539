import { db } from '../service/firebase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  arrayRemove,
  query,
  Timestamp,
  where,
  limit,
  startAfter,
  type DocumentData,
  orderBy
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getStorage, ref, deleteObject } from 'firebase/storage'
import Category from '../models/category'
import {
  type GetPaginatedParam,
  type DeleteCategoryDocumentParam,
  type UpdateCategoryParam,
  type GetFromSearchParam,
  type ParamIdCountry
} from '../models/param'
import UserData from '../models/userData'

export const deleteCategoryDocument = createAsyncThunk(
  'categories/deleteDocument',
  async (data: DeleteCategoryDocumentParam) => {
    try {
      const id = data.id
      const docNumber = data.doc.split('%2F').slice(-1)[0].split('?')[0]
      const categoryType = data.categoryType.replace(' ', '_')
      const path = `/categories/${categoryType}/${id}/docs/${docNumber}`
      const storage = getStorage()

      const storageRef = ref(storage, path)

      await deleteObject(storageRef)

      const docRef = doc(db, 'categorias', data.id)
      await updateDoc(docRef, {
        documentos: arrayRemove(data.doc)
      })
      window.location.reload()
    } catch (error) {
      console.log('Could not delete category picture', error)
    }
  }
)

export interface UpdateCategoryStatusProps {
  id: string
  status: boolean
  userUid: string
}
export const updateCategoryStatus = createAsyncThunk(
  'categories/updateStatus',
  async (data: UpdateCategoryStatusProps) => {
    try {
      const docRef = doc(db, 'categorias', data.id)
      const currentTime = Timestamp.fromDate(new Date())
      const body = {
        enable: data.status
      } as any
      if (data.status) {
        body.startedBy = currentTime
      } else {
        body.finishedBy = currentTime
      }
      await updateDoc(docRef, body)
      const functions = getFunctions()

      const addMessage = httpsCallable(functions, 'sendNotificationV2')
      await addMessage({
        notificationType: data.status ? 'ENABLED' : 'DISABLED',
        extras: {
          categoryUid: data.id
        }
      })
      return data.id
    } catch (error) {
      console.log('Could not update category status', error)
    }
  }
)

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (data: UpdateCategoryParam) => {
    try {
      if (!data.id) return
      const docRef = doc(db, 'categorias', data.id)
      await updateDoc(docRef, {
        title: data.title,
        title_lowercase: data.title?.toLowerCase() ?? null,
        bio: data.bio,
        dni: data.dni,
        tags: data.tags,
        description: data.description,
        businessEmail: data.businessEmail?.toLowerCase() ?? null,
        hours: data.hours,
        paymentDescription: data.paymentDescription ?? null,
        observations: data.observations ?? null,
        paymentAmount: data.paymentAmount ?? null
      })
    } catch (error) {
      console.log('Could not update category status', error)
    }
  }
)

export const getCategories = createAsyncThunk('categories/get', async (data: GetPaginatedParam, thunkAPI) => {
  try {
    const userList: UserData[] = []
    const queryUsers = query(
      collection(db, 'users'),
      where('country', '==', data.country)
    )
    const queryUserSnapshot = await getDocs(queryUsers)
    queryUserSnapshot.forEach((doc) => {
      const user = UserData.fromJson(doc.id, doc.data())
      userList.push(user)
    })

    const list: Category[] = []
    const q = query(
      collection(db, 'categorias'),
      where('country', '==', data.country)
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const item: Category = Category.fromJson(doc.id, doc.data())
      list.push(item)
    })
    return list.sort((a: Category, b: Category) => b.time - a.time)
  } catch (error) {
    console.log('Could not get categories', error)
    return []
  }
})

export interface PaginatedCategoryParam {
  categories: Category[]
  lastVisible: DocumentData | null
  initialRequest: boolean
}

export const getCategoriesPaginated = createAsyncThunk<PaginatedCategoryParam, GetPaginatedParam>(
  'categories/getPaginated',
  async (data: GetPaginatedParam, thunkAPI) => {
    try {
      const { country, initialRequest } = data
      const searchItem = (thunkAPI.getState() as any).category.searchValue as string | null
      // TODO: Research why serachItem is null in the UI
      if (searchItem && !initialRequest) {
        throw new Error('Search value is not null')
      }
      const lastVisibleSaved = (thunkAPI.getState() as any).category.lastVisible as DocumentData | null
      const categoryList: Category[] = []
      let queryCategory

      if (lastVisibleSaved && !initialRequest) {
        queryCategory = query(
          collection(db, 'categorias'),
          where('country', '==', country),
          orderBy('lastLogin', 'desc'),
          limit(30),
          startAfter(lastVisibleSaved)
        )
      } else {
        queryCategory = query(
          collection(db, 'categorias'),
          where('country', '==', country),
          orderBy('lastLogin', 'desc'),
          limit(30)
        )
      }
      const querySnapshotCategory = await getDocs(queryCategory)
      const categoryIds: string[] = []

      let newLastVisible: DocumentData | null = null
      querySnapshotCategory.forEach((doc) => {
        newLastVisible = doc
        categoryIds.push(doc.id)
        const item: Category = Category.fromJson(doc.id, doc.data())
        categoryList.push(item)
      })

      const queryUsers = query(
        collection(db, 'users'),
        where('country', '==', country),
        where('categoryUid', 'in', categoryIds)
      )

      const querySnapshotUser = await getDocs(queryUsers)
      querySnapshotUser.forEach((doc) => {
        const user = UserData.fromJson(doc.id, doc.data())
        const item: Category = categoryList.find((category) => category.userUid === doc.id) as Category
        if (!item) {
          console.log('Could not find category', doc.id)
          return
        }
        item.user = user
      })

      return {
        categories: categoryList.sort((a: Category, b: Category) => b.time - a.time),
        lastVisible: newLastVisible,
        initialRequest: initialRequest ?? false
      }
    } catch (error) {
      console.log('Could not get categories', error)
      return { categories: [], lastVisible: null, initialRequest: false }
    }
  }
)

export const getCategoryFromSearch = createAsyncThunk<Category[], GetFromSearchParam>(
  'categories/getSearch',
  async (data: GetFromSearchParam, thunkAPI) => {
    try {
      const { country, search } = data
      const searchLowerCase = search.toLowerCase()

      const nameQuery = query(
        collection(db, 'categorias'),
        where('country', '==', country),
        where('title_lowercase', '>=', searchLowerCase),
        where('title_lowercase', '<=', `${searchLowerCase}\uf8ff`)
      )

      const emailQuery = query(
        collection(db, 'categorias'),
        where('country', '==', country),
        where('businessEmail', '>=', searchLowerCase),
        where('businessEmail', '<=', `${searchLowerCase}\uf8ff`)
      )

      const userRmailQuery = query(
        collection(db, 'categorias'),
        where('country', '==', country),
        where('userEmail', '>=', searchLowerCase),
        where('userEmail', '<=', `${searchLowerCase}\uf8ff`)
      )

      const nameSnapshot = await getDocs(nameQuery)
      const emailSnapshot = await getDocs(emailQuery)
      const userRmailSnapshot = await getDocs(userRmailQuery)

      const nameCategories = nameSnapshot.docs.map(doc => Category.fromJson(doc.id, doc.data()))
      const emailCategories = emailSnapshot.docs.map(doc => Category.fromJson(doc.id, doc.data()))
      const userRmailCategories = userRmailSnapshot.docs.map(doc => Category.fromJson(doc.id, doc.data()))

      const combined = [...nameCategories, ...emailCategories, ...userRmailCategories].filter((user, index, self) =>
        index === self.findIndex((u) => u.id === user.id)
      )

      return combined
    } catch (error) {
      console.log('Could not get users', error)
      return []
    }
  })

export const getCategorySelected = createAsyncThunk(
  'categories/getSelected',
  async (data: ParamIdCountry) => {
    try {
      let user: UserData | null = null
      if (data.country) {
        const queryUsers = query(
          collection(db, 'users'),
          where('country', '==', data.country),
          where('categoryUid', '==', data.id),
          limit(1)
        )
        const querySnapshotUser = await getDocs(queryUsers)
        user = querySnapshotUser.docs.map(doc => UserData.fromJson(doc.id, doc.data()))[0]
      }

      const docRef = doc(db, 'categorias', data.id)
      const docSnap = await getDoc(docRef)
      const res = docSnap.data()
      const item: Category = Category.fromDoc(data.id, res, user)
      return item
    } catch (error) {
      console.log('Could not get category selected', error)
    }
  }
)
