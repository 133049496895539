import React, { createContext, type ReactNode, useContext, useEffect, useState } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  type UserCredential,
  type User,
  verifyBeforeUpdateEmail,
  updatePassword,
  type Auth
} from 'firebase/auth'
import { auth, db } from '../service/firebase'
import { type DocumentData, doc, getDoc, collection, getDocs, query } from 'firebase/firestore'
import UserData from '../models/userData'
import IAppData from '../models/appData'
import Maintenance from '../shared/Maintenance'
import { getFunctions, httpsCallable } from 'firebase/functions'

export interface AuthProviderProps {
  children?: ReactNode
}

export interface UserContextState {
  isAuthenticated: boolean
  isLoading: boolean
  id?: string
}

export const UserStateContext = createContext<UserContextState>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as UserContextState
)

export interface AuthContextModel {
  auth: Auth
  user: User | null
  myUser: UserData
  appData: IAppData
  logout: () => Promise<void>
  resetPassword: (email: string | undefined) => Promise<void>
  sendResetUserPasswordEmail: (email: string) => Promise<void>
  updateEmail: (email: string) => Promise<void> | undefined
  updatePass: (password: string) => Promise<void> | undefined
  signIn: (email: string, password: string) => Promise<UserCredential>
  signUp: (email: string, password: string) => Promise<UserCredential>
}

export const AuthContext = React.createContext<AuthContextModel>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AuthContextModel
)

export function useAuth(): AuthContextModel {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [myUser, setMyUser] = useState<UserData | null>(null)
  const [appData, setAppData] = useState<IAppData | null>(null)
  const [appStatus, setAppStatus] = useState<boolean>(true)

  const signUp = async (email: string, password: string): Promise<UserCredential> => {
    const credentials = await createUserWithEmailAndPassword(auth, email, password)
    return credentials
  }

  const signIn = async (email: string, password: string): Promise<UserCredential> => {
    const credentials = await signInWithEmailAndPassword(auth, email, password)
    await getUserInformation(credentials.user)
    return credentials
  }

  const sendResetUserPasswordEmail = async (email: string): Promise<void> => {
    try {
      const functions = getFunctions()
      const callable = httpsCallable(functions, 'createUserPasswordV2')
      await callable({
        email
      })
    } catch (e) {
      console.error(e)
    }
  }

  const logout = async (): Promise<void> => {
    await signOut(auth)
  }

  const resetPassword = async (email: string | undefined): Promise<void> => {
    if (email === undefined) return
    await sendPasswordResetEmail(auth, email)
  }

  async function updateEmail(email: string): Promise<void> {
    if (user == null) return
    await verifyBeforeUpdateEmail(user, email)
  }

  async function updatePass(password: string): Promise<void> {
    if (user == null) return
    await updatePassword(user, password)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      await getInitialAppData()
      if (user) {
        await getUserInformation(user)
      }
      setUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const getUserInformation = async (user: User): Promise<void> => {
    try {
      setLoading(true)
      const idToken = await user.getIdTokenResult()
      if (!idToken) {
        throw new Error('No se pudo obtener el token')
      }
      const docRef = doc(db, 'users', user.uid)
      const response = await getDoc(docRef)
      const data = (response).data() as DocumentData
      if (!data) {
        throw new Error('El usuario no existe, registrate desde la App.')
      }
      const responseUser = UserData.fromJson(user.uid, data)

      setMyUser(responseUser)
    } catch (e) {
      await logout()
    } finally {
      setLoading(false)
    }
  }

  const getInitialAppData = async (): Promise<void> => {
    let resultAppData: IAppData = IAppData.fromConstants()
    try {
      const ref = collection(db, 'app_data')
      const snapshot = await getDocs(query(ref))
      const rowAppData = new Map<string, any>()
      snapshot.docs.forEach((doc) => {
        const data = doc.data()
        rowAppData.set(doc.id, data)
      })
      resultAppData = IAppData.fromJson(rowAppData)
      localStorage.setItem(
        'resultAppData',
        JSON.stringify(resultAppData.toJson())
      )
      setAppData(resultAppData)
    } catch (e) {
      const appDataSaved = localStorage.getItem('resultAppData')
      if (appDataSaved) {
        resultAppData = JSON.parse(appDataSaved) as IAppData
        setAppData(resultAppData)
      }
    } finally {
      setAppStatus(resultAppData.status.enable as boolean)
    }
  }

  const values = {
    auth,
    user,
    myUser: myUser!,
    appData: appData!,
    logout,
    resetPassword,
    updateEmail,
    updatePass,
    signIn,
    signUp,
    sendResetUserPasswordEmail
  }

  return (
    <AuthContext.Provider value={values}>
      {!loading && appStatus && children}
      {!appStatus && (
        <Maintenance
          message={
            (appData?.status.message as string) ?? 'Estamos en mantenimiento, vuelva más tarde.'
          }
        />
      )}
    </AuthContext.Provider>
  )
}
